<template>
  <v-card class="mx-5">
    <v-card-title>
      <h1 class="font">
        Register
      </h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="firstName"
          prepend-icon="mdi-account-circle"
          label="First Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          prepend-icon="mdi-account-circle"
          label="Last Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="userName"
          prepend-icon="mdi-account-circle"
          label="Username"
          required
        ></v-text-field>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-account-circle"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          required
        ></v-text-field>
        <v-text-field
          v-model="rePassword"
          :type="showPassword ? 'text' : 'password'"
          label="Verify Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :rules="[rePasswordRules]"
          required
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-row>
      <v-col>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn @click="registerAccount">Register</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// import { mapActions } from 'vuex'
export default {
  name: 'RegisterAccount',
  data() {
    return {
      email: '',
      password: '',
      rePassword: '',
      firstName: '',
      lastName: '',
      showPassword: false,
      userName: ''
    }
  },
  computed: {
    rePasswordRules() {
      return () => (this.password === this.rePassword) || 'Password must match'
    }
  },
  methods: {
    registerAccount() {
      this.$store.dispatch('registerAccount', {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.userName,
      })
    },
  },
}
</script>

<style scoped>
.font {
  font-family: "NunitoSans-Light";
  color: #77787B;
  font-size: 1.5em;
}
</style>